import React from 'react';
import Game from './Game.js';
function Home(){
	return(
	
	<div className="content2" id="jukuri"> 

			<div className="DivPoggers"> 
				<h4><a href="https://youtu.be/vox4teymdGw?t=2" target="blank" className="a1"> poggers </a></h4>
			</div>
			
	<div className="row2">
		<div className="DivList">
			<h2> HEAD</h2>
			
				<p>
				BIG <br/>
				LARGE<br/>
				-------<br/>
				 Look at<br/>
				that boy<br/>
				he lit<br/>
				goddamn he<br/>
				wilding<br/>
				with your<br/>
				mami in<br/>
				Miami
				</p>
				
			
		</div>
		<div className="game">
				<Game />
		</div>
		<div className="DivList">
			<h2> Uhhh</h2>
			<p>
				Umm<br/>
				Hmmm<br/>
				hummm<br/>
				Basically<br/>
				<br/>
				uhhh<br/>
				Lorem Lipsum<br/>
				-----------<br/>
				yeah
				</p>
			
		</div>
	</div>
</div>
	);
}

export default Home;