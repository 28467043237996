import React from 'react';

function Header(){
	return (
	
	
	   <div className="DivHeader">
        <div className="columnHead">
		
		</div>
		
		<div className="columnChad"> 
			<div className="NeonSign"><h1> Chad Check </h1></div>
		</div>
		
		<div className="columnArchives"> 
			 
		</div>
	  </div>
	
	
	
	);
}
export default Header;
