import React from 'react';

function Archives() {
	return (
	
	<div className="content2" id="jukuri"> 

			<div className="DivPoggers"> 
				<h4><a href="https://youtu.be/vox4teymdGw?t=2" target="blank" className="a1"> poggers </a></h4>
			</div>
	<div className="row">
	<div className="columnArchives">
	</div>
		<div className="column">
			<img src="/memes/meme1.jpg" alt="Meme"/> 
			<img src="/memes/meme2.jpg" alt="Meme"/> 
			<img src="/memes/meme3.jpg" alt="Meme"/> 
			<img src="/memes/meme4.jpg" alt="Meme"/> 
			<img src="/memes/meme5.jpg" alt="Meme"/> 
			<img src="/memes/meme6.jpg" alt="Meme"/> 
			<img src="/memes/meme7.jpg" alt="Meme"/> 
			<img src="/memes/meme8.jpg" alt="Meme"/> 
			<img src="/memes/meme9.jpg" alt="Meme"/> 
			<img src="/memes/meme10.jpg" alt="Meme"/> 
			<img src="/memes/meme11.jpg" alt="Meme"/> 
			<img src="/memes/meme34.jpg" alt="Meme"/> 
			<img src="/memes/meme37.jpg" alt="Meme"/> 
		</div>
		<div className="column"> 
			<img src="/memes/meme12.jpg" alt="Meme"/> 
			<img src="/memes/meme13.jpg" alt="Meme"/> 
			<img src="/memes/meme14.jpg" alt="Meme"/> 
			<img src="/memes/meme15.jpg" alt="Meme"/> 
			<img src="/memes/meme16.jpg" alt="Meme"/> 
			<img src="/memes/meme17.jpg" alt="Meme"/> 
			<img src="/memes/meme18.jpg" alt="Meme"/> 
			<img src="/memes/meme19.jpg" alt="Meme"/> 
			<img src="/memes/meme20.jpg" alt="Meme"/> 
			<img src="/memes/meme21.jpg" alt="Meme"/>  
			<img src="/memes/meme35.jpg" alt="Meme"/> 
			<img src="/memes/meme38.jpg" alt="Meme"/> 
		</div>
		<div className="column"> 
			<img src="/memes/meme22.jpg" alt="Meme"/>
			<img src="/memes/meme23.jpg" alt="Meme"/> 
			<img src="/memes/meme24.jpg" alt="Meme"/> 
			<img src="/memes/meme25.jpg" alt="Meme"/> 
			<img src="/memes/meme26.jpg" alt="Meme"/> 
			<img src="/memes/meme27.jpg" alt="Meme"/> 
			<img src="/memes/meme28.jpg" alt="Meme"/> 
			<img src="/memes/meme29.jpg" alt="Meme"/> 
			<img src="/memes/meme30.jpg" alt="Meme"/> 
			<img src="/memes/meme31.jpg" alt="Meme"/> 
			<img src="/memes/meme32.jpg" alt="Meme"/> 
			<img src="/memes/meme33.jpg" alt="Meme"/> 
			<img src="/memes/meme36.jpg" alt="Meme"/> 
		</div>
		<div className="columnArchives">
		</div>
	</div>
</div>

	);
}

export default Archives;