import React from 'react';


function Footer() {
	return (

	<div className="footer">
		<p> Yeah yeah footer yeah</p>
	  </div>
	  
	  );
}
export default Footer;