import React from 'react';

function Dorime() {
	return (
	<div className="content2" id="jukuri"> 

			<div className="DivPoggers"> 
				<h4><a href="https://youtu.be/vox4teymdGw?t=2" target="blank" className="a1"> poggers </a></h4>
			</div>
	<img src="https://cdn.discordapp.com/attachments/466370301739925508/780464820104724490/Dorime_Rat_Banner.png" className="Dorime" alt="The Pope" /> 
	</div>
	);
}

export default Dorime;